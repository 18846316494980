import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import duration from 'dayjs/plugin/duration.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import weekday from 'dayjs/plugin/weekday.js';
import 'dayjs/locale/ru';

// eslint-disable-next-line
dayjs.extend(utc);
// eslint-disable-next-line
dayjs.extend(duration);
// eslint-disable-next-line
dayjs.extend(customParseFormat);
// eslint-disable-next-line
dayjs.extend(weekday);

// eslint-disable-next-line
dayjs.locale('ru');

export default dayjs;
